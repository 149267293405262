


















import Vue from 'vue'
import config from '@/utils/config'
import request from '@/utils/request'
import condition from './dataComponents/condition.vue'
import liveTable from './dataComponents/liveTable.vue'
import liveChart from './dataComponents/liveChart.vue'

export default Vue.extend({
    components: {
        condition,
        liveTable,
        liveChart,
    },
    data() {
        return {
            navSubdata: [{text: '列表'}, {text: '图表'}],
            navsubIndex: 0,
            conditionObj: {},
            electricData: [],
        }
    },
    created() {
        request.get('/admin/warning_threshold/query?element=electric_field_intensity&status=1', null)
        .then((data) => {
            // console.log(data.data)
            this.electricData = data.data
        })
    },
    methods: {
        getCondition(data: any) {
            this.conditionObj = data
        },
        changeTab(index: number) {
            this.navsubIndex = index
        },
    },
})

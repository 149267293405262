<template>
    <div class="chartPanel">
        <!-- <div>{{liveConditionObj}}</div> -->
        <!-- <div class="selectDiv" v-if="liveConditionObj.urlList == '/cw/surface2/list/surf_day_data' || liveConditionObj.urlList == '/cw/surface_data/list/surf_hour_data'">
            <Select class="townList" v-model="selectValue" style="width:90px;margin-left: 20px;" @on-change="changeSelect()">
                <Option v-for="(item, index) in selectList" :value="index" :key="index">{{ item.station_name }}</Option>
            </Select>
        </div> -->
        <div v-show="isShow" style="width:95%;height:94%;margin: 0 auto;">
            <div id="chart" style="width:100%;height:100%;" ref="multiStationChartTable"></div>
        </div>
        <div class="panel-no" v-show="isNotShow">
            <img src="/static/no.png" alt="">
            <div>没有符合查询条件的数据，无法生成折线图</div>
        </div>
    </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
import shandianData from '../../monitor/dataService/shandianData'
import daqiData from '../../monitor/dataService/daqiData'
import signUtils from '@/utils/sign_utils'

export default Vue.extend({
    props: {
        conditionObj: {
            type: Object,
        },
        electricData: {
            type: Array,
        },
    },
    watch: {
        conditionObj() {
            this.getData()
        },
    },
    data() {
        return {
            queryResult: [],
            myChart: {},
            selectList: [],
            selectValue: 0,
            isShow: true,
            isNotShow: false,
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            // console.log(this.electricData)
            const condition = this.conditionObj.condition
            const urlList = this.conditionObj.urlList
            if (condition) {
                let params = {}
                if (condition.typeId === 'shandian') {
                    const km = (condition.warnRangeId).split('km')
                    const lonLat = {}
                    condition.stationList.map((e) => {
                        if (e.id === condition.stationId) {
                            lonLat.lon = e.lon
                            lonLat.lat = e.lat
                        }
                    })
                    params = {
                        timeRange: condition.timeRange,
                        lon: lonLat.lon,
                        lat: lonLat.lat,
                        radius: `${km[0]}000`,
                    }
                    signUtils(params)
                    // console.log(params)
                    shandianData.thunderData(params).then((data) => {
                        this.isShow = true
                        this.isNotShow = false
                        // console.log(data)
                        const value0 = data.map((e) => `${e.lon},${e.lat}`)
                        const value1 = data.map((e) => e.height)
                        const value2 = data.map((e) => e.intensity)
                        const value3 = data.map((e) => e.slope)
                        value0.unshift('经纬度')
                        value1.unshift('高度')
                        value2.unshift('强度')
                        value3.unshift('陡度')
                        this.myChart = this.$echarts.init(this.$refs.multiStationChartTable);
                        // console.log(value0, value1, value2, value3)
                        const option = this.createChartThunder(value0, value1, value2, value3)
                        this.myChart.setOption(option, true);
                        window.resize = this.myChart.resize;  // 图表宽度自适应
                    }).catch(() => {
                        this.loading = false
                    })
                } else if (condition.typeId === 'daqi') {
                    this.isShow = true
                    this.isNotShow = false
                    params = {
                        timeRange: condition.timeRange,
                        maxValue: condition.maxValue,
                        minValue: condition.minValue,
                    }
                    daqiData.electricDataList(params).then((data) => {
                        // console.log(data)
                        data.map((e) => {
                            // e.date = e.createtime.split('T')[1]
                            e.date = e.createtime.split('T').join(' ')
                            e.value = e.average
                        })
                        const piecesData = []
                        const colorArr = ['#008000', '#00A0E9', '#E4FF00', '#FFAE00', '#FF0000']
                        this.electricData.map((e, index) => {
                            piecesData.push({
                                gt: e.minValue, lte: e.maxValue, color: colorArr[index],
                            })
                        })
                        // console.log(piecesData)
                        // const piecesData1 = [
                        //     {gt: 20, lte: 50, color: '#00A0E9'},
                        //     {gt: 50, lte: 100, color: '#E4FF00'},
                        //     {gt: 100, lte: 150, color: '#FFAE00'},
                        //     {gt: 150, lte: 500, color: '#FF0000'},
                        // ]
                        // console.log(piecesData1)
                        this.createChart(
                            data.map((e) => e.date),
                            data.map((e) => e.value),
                            piecesData,
                        );
                    }).catch(() => {
                        this.loading = false
                    })
                }
            }
        },
        createChartThunder(value0, value1, value2, value3) {
            const color = ['#D01864', '#F3C525', '#008000', '#18BDD0']
            const option = {
                color,
                legend: {
                    textStyle: {
                        color: '#fff',
                    },
                    icon: 'roundRect',
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '5%',
                    containLabel: true,
                },
                dataset: {
                    source: [
                        value0,
                        value1,
                        value2,
                        value3,
                    ],
                },
                dataZoom: [
                    {
                        type: 'slider',
                        height: 5,
                        bottom: 20,
                        right: 25,
                        realtime: true,
                        start: 0,
                        end: 100,
                        fillerColor: '#26A57D',
                        borderColor: 'transparent',
                        backgroundColor: 'gray',
                        handleIcon: 'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7v-1.2h6.6z M13.3,22H6.7v-1.2h6.6z M13.3,19.6H6.7v-1.2h6.6z', // jshint ignore:line
                        handleSize: 10,
                        textStyle: {
                            color: '#fff',
                        },
                    }, {
                        type: 'inside',
                    },
                ],
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: '#fff',
                                fontSize: 16,
                            },
                            rotate: 0,
                        },
                        axisLine: {
                            lineStyle: {color: '#fff'},
                        },
                        axisTick: {
                           show: false,
                        },
                    },
                ],
                yAxis : [
                    {
                        type: 'value',
                        nameTextStyle: {
                            color: '#fff',
                            // backgroundColor: 'red',
                            align: 'left',
                            rich: {
                                a: {
                                    width: '10%',
                                    align: 'right',
                                },
                            },
                        },
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: 'transparent',
                                fontSize: 14,
                            },
                            rotate: 0,
                        },
                        axisLine: {
                            lineStyle: {color: '#fff'},
                        },
                        axisTick: {
                           show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {type: 'bar', seriesLayoutBy: 'row', label: {
                        normal: {
                                show: true,
                                position: 'top',
                                fontSize: 16,
                            },
                        },
                        barGap: 0,
                    },
                    {type: 'bar', seriesLayoutBy: 'row', label: {
                        normal: {
                                show: true,
                                position: 'top',
                                fontSize: 16,
                            },
                        },
                    },
                    {type: 'bar', seriesLayoutBy: 'row', label: {
                        normal: {
                                show: true,
                                position: 'top',
                                fontSize: 16,
                            },
                        },
                    },
                ],
            };
            return option
        },
        createChart(date, value, piecesData) {
            // @ts-ignore
            const myChart = this.$echarts.init(document.getElementById('chart')); // 基于准备好的dom，初始化echarts实例
            window.onresize = myChart.resize;  // 图表宽度自适应
            const colors = ['#EC9E41', '#00E9FF'];
            const option = {
                color: colors,
                tooltip: {
                    show: true,
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#fff',
                        },
                    },
                },
                grid: {
                    x: 30,
                    y: 10,
                    x2: 20,
                    y2: 20,
                },
                xAxis: {
                    type: 'category',
                    show: true,
                    boundaryGap: true,
                    data: date,
                    axisLabel: {
                        color: '#fff',
                        // fontSize: 14,
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {color: '#fff'},
                    },
                    axisTick: {
                        show: false,
                    },
                    axisPointer: {
                        type: 'shadow',
                        label: {
                            // show: false,
                            color: '#000',
                        },
                    },
                },
                yAxis: {
                    type: 'value',
                    show: true,
                    // name: '温度/℃',
                    // nameTextStyle: {
                    //     color: '#fff',
                    // },
                    splitLine: {
                        show: false,
                    },
                    axisLabel: {
                        color: '#fff',
                        // fontSize: 14,
                    },
                    axisLine: {
                        show: false,
                        lineStyle: {color: '#fff'},
                    },
                    axisTick: {
                        show: false,
                    },
                    axisPointer: {
                        type: 'line',
                        label: {
                            // show: false,
                            color: '#000',
                        },
                    },
                },
                visualMap: {
                    top: 10,
                    right: 10,
                    show: false,
                    pieces: piecesData,
                    outOfRange: {
                        color: '#999',
                    },
                },
                series: [
                    {
                        type: 'line',
                        smooth: true,
                        // itemStyle: {
                        //     normal: {
                        //         label: {
                        //             show: true,
                        //             position: 'top',
                        //             textStyle: {color: '#fff', fontSize: 16},
                        //         },
                        //         lineStyle: {
                        //             width: 4,
                        //             color: '#00E9FF',
                        //         },
                        //     },
                        // },
                        data: value,
                        // markLine: {
                        //     silent: true,
                        //     lineStyle: {
                        //         color: '#808080'
                        //     },
                        //     data: [
                        //         {yAxis: 5},
                        //         {yAxis: 10},
                        //         {yAxis: 15},
                        //         {yAxis: 20},
                        //     ],
                        // },
                    },
                ],
            };
            myChart.setOption(option);
        },
        // 全要素统计分析 -- 统计小时数据
        hourDataSta() {
            const ele = this.liveConditionObj.ele
            const stationName = this.queryResult.map((e) => e.station_name)
            stationName.unshift('站名')
            // console.log(this.queryResult)
            let value0 = []
            let value1 = []
            if (ele === 'pre_1h') {
                value0 = this.queryResult.map((e) => e.pre_1h)
                value1 = this.queryResult.map((e) => e.ly_pre_1h)
                value0.unshift('降水')
                value1.unshift('上年同期')
            }
            if (ele === 'win_s_inst_max') {
                value0 = this.queryResult.map((e) => e.win_s_inst_max)
                value0.unshift('最大风速')
            }
            this.myChart = this.$echarts.init(this.$refs.multiStationChartTable);
            // console.log(value0, value1)
            const option = this.createChartSta(stationName, value0, value1)
            this.myChart.setOption(option, true);
            window.resize = this.myChart.resize;  // 图表宽度自适应
        },
        // 全要素统计分析 -- 统计数据  统计日数据
        handleDataSta() {
            const ele = this.liveConditionObj.ele
            const stationName = this.queryResult.map((e) => e.station_name)
            stationName.unshift('站名')
            let value0 = []
            let value1 = []
            let value2 = []
            let name = ''
            if (ele === 'tem_avg') {
                value0 = this.queryResult.map((e) => e.tem_avg)
                value1 = this.queryResult.map((e) => e.ly_tem_avg)
                value2 = this.queryResult.map((e) => e.l30y_tem_avg)
                value0.unshift('平均气温')
                value1.unshift('上年同期')
                value2.unshift('30年平均')
                name = '气温/℃'
            }
            if (ele === 'tem_min') {
                value0 = this.queryResult.map((e) => e.tem_min)
                value1 = this.queryResult.map((e) => e.ly_tem_min)
                value2 = this.queryResult.map((e) => e.l30y_tem_min)
                value0.unshift('最低气温')
                value1.unshift('上年同期')
                value2.unshift('30年平均')
                name = '气温/℃'
            }
            if (ele === 'tem_max') {
                value0 = this.queryResult.map((e) => e.tem_max)
                value1 = this.queryResult.map((e) => e.ly_tem_max)
                value2 = this.queryResult.map((e) => e.l30y_tem_max)
                value0.unshift('最高气温')
                value1.unshift('上年同期')
                value2.unshift('30年平均')
                name = '气温/℃'
            }
            if (ele === 'gst_avg') {
                value0 = this.queryResult.map((e) => e.gst_avg)
                value1 = this.queryResult.map((e) => e.ly_gst_avg)
                value2 = this.queryResult.map((e) => e.l30y_gst_avg)
                value0.unshift('平均气温')
                value1.unshift('上年同期')
                value2.unshift('30年平均')
                name = '气温/℃'
            }
            if (ele === 'ssh') {
                value0 = this.queryResult.map((e) => e.ssh)
                value1 = this.queryResult.map((e) => e.ly_ssh)
                value2 = this.queryResult.map((e) => e.l30y_ssh)
                value0.unshift('日照时数')
                value1.unshift('上年同期')
                value2.unshift('30年平均')
                name = '日照/h'
            }
            if (ele === 'rhu_avg') {
                value0 = this.queryResult.map((e) => e.rhu_avg)
                value1 = this.queryResult.map((e) => e.ly_rhu_avg)
                value2 = this.queryResult.map((e) => e.l30y_rhu_avg)
                value0.unshift('相对湿度')
                value1.unshift('上年同期')
                value2.unshift('30年平均')
                name = '湿度/%'
            }
            if (ele === 'pre_time_0808') {
                value0 = this.queryResult.map((e) => e.pre_time_0808)
                value1 = this.queryResult.map((e) => e.ly_pre_time_0808)
                value2 = this.queryResult.map((e) => e.l30y_pre_time_0808)
                value0.unshift('累计降水量')
                value1.unshift('上年同期')
                value2.unshift('30年平均')
                name = '降水/mm'
            }
            if (ele === 'pre_time_2020') {
                value0 = this.queryResult.map((e) => e.pre_time_2020)
                value1 = this.queryResult.map((e) => e.ly_pre_time_2020)
                value2 = this.queryResult.map((e) => e.l30y_pre_time_2020)
                value0.unshift('累计降水量')
                value1.unshift('上年同期')
                value2.unshift('30年平均')
                name = '降水/mm'
            }
            this.myChart = this.$echarts.init(this.$refs.multiStationChartTable);
            const option = this.createChartSta(stationName, value0, value1, value2, name)
            this.myChart.setOption(option, true);
            window.resize = this.myChart.resize;  // 图表宽度自适应
        },
        createChartSta(stationName, value0, value1, value2, name) {
            const color = ['#D01864', '#F3C525', '#008000', '#18BDD0']
            const option = {
                color,
                legend: {
                    textStyle: {
                        color: '#fff',
                    },
                    icon: 'roundRect',
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '10%',
                    containLabel: true,
                },
                dataset: {
                    source: [
                        stationName,
                        value0,
                        value1,
                        value2,
                    ],
                },
                dataZoom: [
                    {
                        type: 'slider',
                        height: 5,
                        bottom: 20,
                        right: 25,
                        realtime: true,
                        start: 0,
                        end: 80,
                        fillerColor: '#26A57D',
                        borderColor: 'transparent',
                        backgroundColor: 'gray',
                        handleIcon: 'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7v-1.2h6.6z M13.3,22H6.7v-1.2h6.6z M13.3,19.6H6.7v-1.2h6.6z', // jshint ignore:line
                        handleSize: 10,
                        textStyle: {
                            color: '#fff',
                        },
                    }, {
                        type: 'inside',
                    },
                ],
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: '#fff',
                                fontSize: 14,
                            },
                            rotate: 0,
                        },
                        axisLine: {
                            lineStyle: {color: '#fff'},
                        },
                        axisTick: {
                           show: false,
                        },
                    },
                ],
                yAxis : [
                    {
                        type: 'value',
                        name,
                        nameTextStyle: {
                            color: '#fff',
                            // backgroundColor: 'red',
                            align: 'left',
                            rich: {
                                a: {
                                    width: '10%',
                                    align: 'right',
                                },
                            },
                        },
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: 'transparent',
                                fontSize: 14,
                            },
                            rotate: 0,
                        },
                        axisLine: {
                            lineStyle: {color: '#fff'},
                        },
                        axisTick: {
                           show: false,
                        },
                        splitLine: {
                            show: false,
                        },
                    },
                ],
                series: [
                    {type: 'bar', seriesLayoutBy: 'row', label: {
                        normal: {
                                show: true,
                                position: 'top',
                            },
                        },
                        barGap: 0,
                    },
                    {type: 'bar', seriesLayoutBy: 'row', label: {
                        normal: {
                                show: true,
                                position: 'top',
                            },
                        },
                    },
                    {type: 'bar', seriesLayoutBy: 'row', label: {
                        normal: {
                                show: true,
                                position: 'top',
                            },
                        },
                    },
                ],
            };
            return option
        },
        // 全要素统计分析 -- 历史数据
        changeSelect() {
            // console.log(this.selectValue)
            // console.log(this.selectList[this.selectValue])
            const urlList = this.liveConditionObj.urlList
            if (urlList === '/cw/surface2/list/surf_day_data') {
                this.handleDataHis()
            } else if (urlList === '/cw/surface_data/list/surf_hour_data') {
                this.hourDataHis()
            }
        },
        hourDataHis() {
            const station_id_c = this.selectList[this.selectValue].station_id_c
            // console.log(station_id_c)
            // console.log(this.queryResult)
            const arrNew = []
            for (const i of this.queryResult) {
                if (i.station_id_c === station_id_c) {
                    arrNew.push(i)
                }
            }
            // console.log(arrNew)
            const ele = this.liveConditionObj.ele
            const times = arrNew.map((e) => this.formatDate1(e.datetime))
            times.unshift('时间')
            let value0 = []
            if (ele === 'tem_min') {
                value0 = arrNew.map((e) => e.tem_min)
                value0.unshift('最低气温')
            }
            if (ele === 'tem_max') {
                value0 = arrNew.map((e) => e.tem_max)
                value0.unshift('最高气温')
            }
            if (ele === 'pre_1h') {
                value0 = arrNew.map((e) => e.pre_1h)
                value0.unshift('降水')
            }
            if (ele === 'rhu') {
                value0 = arrNew.map((e) => e.rhu)
                value0.unshift('相对湿度')
            }
            if (ele === 'prs') {
                value0 = arrNew.map((e) => e.prs)
                value0.unshift('气压')
            }
            if (ele === 'win_s_max') {
                value0 = arrNew.map((e) => e.win_s_max)
                value0.unshift('最大风速')
            }
            this.myChart = this.$echarts.init(this.$refs.multiStationChartTable);
            const option = this.createChartHis(times, value0)
            this.myChart.setOption(option, true);
            window.resize = this.myChart.resize;  // 图表宽度自适应
        },
        handleDataHis() {
            const station_id_c = this.selectList[this.selectValue].station_id_c
            // console.log(station_id_c)
            // console.log(this.queryResult)
            const arrNew = []
            for (const i of this.queryResult) {
                if (i.station_id_c === station_id_c) {
                    arrNew.push(i)
                }
            }
            // console.log(arrNew)
            const ele = this.liveConditionObj.ele
            const times = arrNew.map((e) => this.formatDate(e.datetime))
            times.unshift('时间')
            let value0 = []
            let value1 = []
            let value2 = []
            let name = ''
            if (ele === 'tem_avg') {
                value0 = arrNew.map((e) => e.tem_avg)
                value1 = arrNew.map((e) => e.ly_tem_avg)
                value2 = arrNew.map((e) => e.l30y_tem_avg)
                value0.unshift('平均气温')
                value1.unshift('上年同期')
                value2.unshift('30年平均')
                name = '气温/℃'
            }
            if (ele === 'tem_min') {
                value0 = arrNew.map((e) => e.tem_min)
                value1 = arrNew.map((e) => e.ly_tem_min)
                value2 = arrNew.map((e) => e.l30y_tem_min)
                value0.unshift('最低气温')
                value1.unshift('上年同期')
                value2.unshift('30年平均')
                name = '气温/℃'
            }
            if (ele === 'tem_max') {
                value0 = arrNew.map((e) => e.tem_max)
                value1 = arrNew.map((e) => e.ly_tem_max)
                value2 = arrNew.map((e) => e.l30y_tem_max)
                value0.unshift('最高气温')
                value1.unshift('上年同期')
                value2.unshift('30年平均')
                name = '气温/℃'
            }
            if (ele === 'gst_avg') {
                value0 = arrNew.map((e) => e.gst_avg)
                value1 = arrNew.map((e) => e.ly_gst_avg)
                value2 = arrNew.map((e) => e.l30y_gst_avg)
                value0.unshift('平均气温')
                value1.unshift('上年同期')
                value2.unshift('30年平均')
                name = '气温/℃'
            }
            if (ele === 'ssh') {
                value0 = arrNew.map((e) => e.ssh)
                value1 = arrNew.map((e) => e.ly_ssh)
                value2 = arrNew.map((e) => e.l30y_ssh)
                value0.unshift('日照时数')
                value1.unshift('上年同期')
                value2.unshift('30年平均')
                name = '日照/h'
            }
            if (ele === 'rhu_avg') {
                value0 = arrNew.map((e) => e.rhu_avg)
                value1 = arrNew.map((e) => e.ly_rhu_avg)
                value2 = arrNew.map((e) => e.l30y_rhu_avg)
                value0.unshift('相对湿度')
                value1.unshift('上年同期')
                value2.unshift('30年平均')
                name = '湿度/%'
            }
            if (ele === 'pre_time_0808') {
                value0 = arrNew.map((e) => e.pre_time_0808)
                value1 = arrNew.map((e) => e.ly_pre_time_0808)
                value2 = arrNew.map((e) => e.l30y_pre_time_0808)
                value0.unshift('累计降水量')
                value1.unshift('上年同期')
                value2.unshift('30年平均')
                name = '降水/mm'
            }
            if (ele === 'pre_time_2020') {
                value0 = arrNew.map((e) => e.pre_time_2020)
                value1 = arrNew.map((e) => e.ly_pre_time_2020)
                value2 = arrNew.map((e) => e.l30y_pre_time_2020)
                value0.unshift('累计降水量')
                value1.unshift('上年同期')
                value2.unshift('30年平均')
                name = '降水/mm'
            }
            const minArr = []
            minArr.push(Math.ceil(Math.min(...value0)), Math.ceil(Math.min(...value1)), Math.ceil(Math.min(...value2)))
            const maxArr = []
            maxArr.push(Math.ceil(Math.max(...value0)), Math.ceil(Math.max(...value1)), Math.ceil(Math.max(...value2)))
            const min = Math.ceil(Math.max(...minArr))
            const max = Math.floor(Math.min(...maxArr))
            this.myChart = this.$echarts.init(this.$refs.multiStationChartTable);
            const option = this.createChartHis(times, value0, value1, value2, min, max, name)
            this.myChart.setOption(option, true);
            window.resize = this.myChart.resize;  // 图表宽度自适应
        },
        createChartHis(times, value0, value1, value2, min, max, name) {
            const color = ['#D01864', '#F3C525', '#008000', '#18BDD0']
            const option = {
                color,
                legend: {
                    textStyle: {
                        color: '#fff',
                    },
                    icon: 'roundRect',
                },
                grid: {
                    left: '0%',
                    right: '0%',
                    bottom: '10%',
                    containLabel: true,
                },
                dataset: {
                    source: [
                        times,
                        value0,
                        value1,
                        value2,
                    ],
                },
                dataZoom: [
                    {
                        type: 'slider',
                        height: 5,
                        bottom: 20,
                        right: 5,
                        realtime: true,
                        start: 0,
                        end: 80,
                        fillerColor: '#26A57D',
                        borderColor: 'transparent',
                        backgroundColor: 'gray',
                        handleIcon: 'M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7v-1.2h6.6z M13.3,22H6.7v-1.2h6.6z M13.3,19.6H6.7v-1.2h6.6z', // jshint ignore:line
                        handleSize: 10,
                        textStyle: {
                            color: '#fff',
                        },
                    }, {
                        type: 'inside',
                    },
                ],
                xAxis: [
                    {
                        type: 'category',
                        axisTick: {
                            alignWithLabel: true,
                        },
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: '#fff',
                                fontSize: 14,
                            },
                            rotate: 0,
                        },
                        axisLine: {
                            lineStyle: {color: '#fff'},
                        },
                        axisTick: {
                           show: false,
                        },
                    },
                ],
                yAxis : [
                    {
                        type: 'value',
                        min,
                        max,
                        name,
                        nameTextStyle: {
                            color: '#fff',
                            // backgroundColor: 'red',
                            align: 'left',
                            rich: {
                                a: {
                                    width: '10%',
                                    align: 'right',
                                },
                            },
                        },
                        axisLabel: {
                            interval: 0,
                            textStyle: {
                                color: 'transparent',
                                fontSize: 14,
                            },
                            rotate: 0,
                        },
                        axisLine: {
                            lineStyle: {color: '#fff'},
                        },
                        splitLine: {
                            show: false,
                        },
                        axisTick: {
                           show: false,
                        },
                    },
                ],
                series: [
                    {type: 'line', smooth: true, seriesLayoutBy: 'row', label: {
                        normal: {
                                show: true,
                                position: 'top',
                            },
                        },
                        lineStyle: {
                            width: 5,
                        },
                    },
                    {type: 'line', smooth: true, seriesLayoutBy: 'row', label: {
                        normal: {
                                show: true,
                                position: 'top',
                            },
                        },
                        lineStyle: {
                            width: 5,
                        },
                    },
                    {type: 'line', smooth: true, seriesLayoutBy: 'row', label: {
                        normal: {
                                show: true,
                                position: 'top',
                            },
                        },
                        lineStyle: {
                            width: 5,
                        },
                    },
                ],
            };
            return option
        },
        formatDate(value) {
            if (value === '--') {
                return '--'
            }
            return moment(value, 'YYYYMMDDHHmmss').add(8, 'hours').format('MM/DD')
        },
        formatDate1(value) {
            if (value === '--') {
                return '--'
            }
            return moment(value, 'YYYYMMDDHHmmss').add(8, 'hours').format('MM/DD/HH:mm')
        },
    },
})
</script>
<style scoped>
    .chartPanel{
        width: 100%;
        height: 100%;
        position: relative;
        padding-top: 10px;
    }
    ::v-deep .ivu-select-selection{
        height: 30px;
        background-color: transparent;
        border-color: #fff;
    }
    ::v-deep .ivu-select-single .ivu-select-selection .ivu-select-selected-value{
        color: #fff;
    }
    .panel-no{
    height: 100%;
    width: calc(100% - 20px);
    margin: 0 10px;
    position: relative;
  }
  .panel-no img{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%) translateY(-40%);
  }
  .panel-no div{
    font-size:24px;
    font-weight:400;
    color: #1E4A7C;
    line-height:28px;
    text-align: center;
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
</style>
<template>
    <div style="height:100%;">
        <Table class="table-list" :loading="loading" :columns="columns" :data="data.list" max-height="550" no-data-text="没有符合查询条件的数据" @on-sort-change='columnSort'>
            <template slot-scope="{ row }" slot="datetime">
                {{ row.datetime | momentTime() }}
            </template>
        </Table>
        <div v-if="typeId === 'daqi'" class="page-nav"><Page show-total :total="data.total" :current.sync='pageNum' :page-size='pageSize' @on-change="changPageNum"/></div>
    </div>
</template>
<script>
import Vue from 'vue'
import moment from 'moment'
import request from '@/utils/request'
import shandianData from '../../monitor/dataService/shandianData'
import daqiData from '../../monitor/dataService/daqiData'
import signUtils from '@/utils/sign_utils'

const columnsElectric = [
    {title: '名称', key: 'name'},
    // {title: '状态', key: 'station_name'},
    // {title: '强度', key: 'station_name'},
    // {title: '预警等级', key: 'tem_avg', sortable: 'custom'},
    {title: '时间', key: 'time'},
    {title: '最高值(v/m)', key: 'highvalue'},
    {title: '最低值(v/m)', key: 'lowvalue'},
    {title: '平均值(v/m)', key: 'average'},
    {title: '通信状态', key: 'communicationStr'},
    {title: '设备状态', key: 'statusStr'},
    // {title: '温度', key: 'temperature'},
    // {title: '电源电压', key: 'voltage'},
    // {title: '转速', key: 'rotate'},
    // {title: '震动频率', key: 'frequency'},
    // {title: '上年同期', key: 'ly_tem_avg'},
    // {title: '历史平均', key: 'l30y_tem_avg'},
    // {title: '距平', key: 'tem_diff_avg'},
]
const columnsThunder = [
    {title: '经度', key: 'lon'},
    {title: '纬度', key: 'lat'},
    {title: '时间', slot: 'datetime'},
    {title: '高度(km)', key: 'height'},
    {title: '强度(kA)', key: 'lit_current'},
    {title: '陡度(kA/μs)', key: 'mars_3'},
]
export default Vue.extend({
    props: {
        conditionObj: {
            type: Object,
        },
    },
    watch: {
        conditionObj() {
            this.pageNum = 1
            this.pageSize = 10
            this.orderColumn = ''
            this.getData()
        },
    },
    data() {
        return {
            loading: true,
            pageNum: 1,
            pageSize: 10,
            columns: columnsThunder,
            orderColumn: '',
            data: {
                total: 0,
                list: [],
            },
            typeId: '',
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        columnSort(columnData) {
            if (columnData.order !== 'normal') {
                this.orderColumn = columnData.key + ' ' + columnData.order
            }
            this.getData()
        },
        changPageNum(num) {
            this.pageNum = num
            this.getData()
        },
        getData() {
            this.loading = true
            // console.log(this.conditionObj)
            const { pageNum, pageSize } = this
            const condition = this.conditionObj.condition
            const urlpage = this.conditionObj.urlpage
            // console.log(condition)
            // console.log(urlpage)
            if (condition) {
                this.typeId = condition.typeId
                let params = {}
                if (condition.typeId === 'shandian') {
                    this.columns = columnsThunder
                    const km = (condition.warnRangeId).split('km')
                    const lonLat = {}
                    condition.stationList.map((i) => {
                        if (i.id === condition.stationId) {
                            lonLat.lon = i.lon
                            lonLat.lat = i.lat
                        }
                    })
                    const arr = condition.timeRange.split(',')
                    // console.log(arr)
                    const s = moment(arr[0], 'YYYYMMDDHHmmss').subtract(8, 'hours').format('YYYYMMDDHHmmss')
                    const e = moment(arr[1], 'YYYYMMDDHHmmss').subtract(8, 'hours').format('YYYYMMDDHHmmss')
                    params = {
                        timeRange: `${s},${e}`,
                        lon: lonLat.lon,
                        lat: lonLat.lat,
                        radius: `${km[0]}000`,
                    }
                    signUtils(params)
                    // console.log(params)
                    shandianData.thunderData(params).then((data) => {
                      data =  JSON.parse(data)
                        this.data.list = data
                        this.loading = false
                    }).catch(() => {
                        this.loading = false
                    })
                } else if (condition.typeId === 'daqi') {
                    this.columns = columnsElectric
                    params = {
                        timeRange: condition.timeRange,
                        pageNum,
                        pageSize,
                        maxValue: condition.maxValue,
                        minValue: condition.minValue,
                    }
                    daqiData.electricDataPage(params).then((data) => {
                        // console.log(data)
                        if (JSON.stringify(data) !== '[]') {
                            const records = data.records
                            records.map((e) => {
                                // e.name = '站号' + e.deviceid
                                e.name = '西安输油末站'
                                e.time = e.createtime.split('T').join(' ')
                                e.communicationStr = e.communication === '1' ? '正常' : '中断'
                                e.statusStr = e.status === '1' ? '正常' : '离线'
                            })
                            this.data.list = records
                            this.data.total = data.total
                        } else {
                            this.data.list = []
                            this.data.total = 0
                        }
                        this.loading = false
                    }).catch(() => {
                        this.loading = false
                    })
                }
            }
        },
        formatDate(value) {
            const url = this.conditionObj.url
            if (value === '- -') {
                return '- -'
            } else {
                if (url === '/cw/surface_data/surf_hour_data') {
                    return moment(value, 'YYYYMMDDHHmmss').add(8, 'hours').format('YYYY-MM-DD HH:mm:ss')
                } else if (url === '/cw/surface2/page/surf_day_data') {
                    return moment(value, 'YYYYMMDDHHmmss').add(8, 'hours').format('YYYY-MM-DD')
                }
            }
            // return moment(value, 'YYYYMMDDHHmmss').add(8, 'hours').format('YYYY-MM-DD HH:mm:ss')
        },
        isItEmpty(value) {
            if (value === 0 || value === '0' ) {
                return value
            } else if (!value || value === undefined) {
                return '- -'
            } else {
                return value
            }
            // return value ? value : '- -'
        },
        toFixedData(val) {
            if (val === '- -' || typeof val !== 'number') {
                return '- -'
            }
            return val.toFixed(1)
        },
        toPercentdData(num, total) {
            num = parseFloat(num)
            total = parseFloat(total)
            if (isNaN(num) || isNaN(total)) {
                return '- -';
            }
            return total <= 0 ? '- -' : (Math.round(num / total * 10000) / 100.00);
        },
    },
    filters: {
        momentTime(time) {
            return moment(time, 'YYYYMMDDHHmmss').add(8, 'hours').format('YYYY-MM-DD HH:mm:ss')
        },
    },
})
</script>
<style scoped>
 .ivu-table-wrapper {
    position: relative;
    border: none;
    border-right: 0;
    overflow: hidden;
}
::v-deep .ivu-table{
    background: transparent;
    color: #fff;
    font-size: 16px;
    /* background: red; */
}
::v-deep .ivu-table:before{
    background-color: transparent;
}
::v-deep .ivu-table th{
    background: #fff;
    border-color: #00A0E9;
}
::v-deep .ivu-table-header{
    border-radius: 8px;
}
::v-deep .ivu-table-header thead tr th {
    background: #00A0E9;
    border-color: #00A0E9;
    color: #fff;
}
::v-deep .ivu-table td {
    background: transparent;
    border-color: #00A0E9;
}
::v-deep .ivu-table-cell {
    padding-right: 0;
    padding-left: 13px;
    display: block;
    text-align: center;
}
/* 分页 */
.page-nav {
    color: #fff;
    margin-top: 20px;
    /* background: red; */
}
::v-deep .ivu-page-prev,::v-deep .ivu-page-next {
    background: transparent;
    border: 1px solid #0077EE;
}
::v-deep .ivu-page-item{
    background: transparent;
    border: 1px solid #0077EE;
}
::v-deep .ivu-page-item a{
    color: #fff;
}
::v-deep .ivu-page-item-active a{
    color: #0077EE;
}
/* 表格选中 */
/* ::v-deep .ivu-table-row-highlight td, .ivu-table-stripe .ivu-table-body tr.ivu-table-row-highlight:nth-child(2n) td, .ivu-table-stripe .ivu-table-fixed-body tr.ivu-table-row-highlight:nth-child(2n) td, tr.ivu-table-row-highlight.ivu-table-row-hover td{
    background:rgba(13,180,180,.4);
}
::v-deep tr.ivu-table-row-hover td{
    background:rgba(13,180,180,.4);
} */
/* 滚动 + 加载中 */
::v-deep .ivu-table-overflowY::-webkit-scrollbar{
    width: 10px;
    height: 10px;
}
::v-deep .ivu-table-overflowY::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #fff;
}
::v-deep .ivu-table-overflowY::-webkit-scrollbar-track {
    border-radius: 5px;
    border: 1px solid #fff;
}
::v-deep .ivu-spin-fix{
    background-color: transparent;
}
::v-deep .ivu-table-wrapper>.ivu-spin-fix{
    border: none;
}
</style>
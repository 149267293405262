




























































































import Vue from 'vue'
import axios from 'axios'
import moment from 'moment'
import config from '@/utils/config'
import request from '@/utils/request'

export default Vue.extend({
    data() {
        return {
            checkList: ['历史查询', '统计查询'],
            checkIndex: 0,
            // typeId: 'shandian',
            typeId: 'daqi',
            stationList: [
                {
                    id: '0',
                    // name: '站号20024',
                    name: '西安输油末站',
                    imgIcon: '/static/image/monitor/legend_1.png',
                    lon: '109.13478330776976',
                    lat: '34.3400605877569',
                },
            ],
            stationId: '0',
            timeList: [
                {id: 3, text: '年'},
                {id: 2, text: '月'},
                {id: 1, text: '日'},
                {id: 0, text: '小时'},
            ],
            timeIndex: 0,
            starttime: new Date(),
            startTimeOptions: {
                disabledDate: (date: any) => {
                    return date && date.valueOf() > Date.now()
                     || (date.valueOf() < moment().add(-3, 'years').valueOf());
                },
            },
            endtime: new Date() ,
            endTimeOptions: {
                disabledDate: (date: any) => {
                    return date && date.valueOf() > Date.now()
                     || (date.valueOf() < moment().add(-3, 'years').valueOf());
                },
            },
            timeStartList: []as any,
            selectedStartHour: '',
            timeEndList: []as any,
            selectedEndHour: '',
            stateList: [{id: 'in', text: '在线'}, {id: 'out', text: '离线'}],
            stateId: ['in', 'out'],
            // warnlevelList: [
            //     {id: 'all', text: '全部'},
            //     {id: '1', text: '一级'},
            //     {id: '2', text: '二级'},
            //     {id: '3', text: '三级'},
            //     {id: 'null', text: '无'},
            // ],
            // warnlevelId: ['all', '1', '2', '3', 'null'],
            warnlevelList: []as any,
            warnlevelId: ''as any,
            warnRangeList: ['5km', '10km', '20km', '50km'],
            warnRangeId: '50km',
            // warnRangeList: []as any,
            // warnRangeId: '',
            urlpage: '/cw/surface2/page/surf_day_data',
            urlOut: '/cw/surface2/export/surf_day_data',
            urlList: '/cw/surface2/list/surf_day_data',
        }
    },
    mounted() {
        Promise.all([
            this.getWarnRangeData(),
            this.getdaqiWarnRangeData(),
        ]).then(() => {
            this.changeTime()
            this.queryCondition()
        })
    },
    methods: {
        checkUrl(index: any) {
            this.checkIndex = index
            this.changeTime()
            this.queryCondition()
        },
        changeTime() {
            if (this.timeIndex === 0) {
                const dateStart = new Date()
                dateStart.setDate(dateStart.getDate() - 0)
                this.starttime = dateStart
                this.endtime = new Date()
                this.hourTime()
            } else if (this.timeIndex === 1) {
                const dateStart = new Date()
                dateStart.setDate(dateStart.getDate() - 1)
                this.starttime = dateStart
                this.endtime = new Date()
            } else if (this.timeIndex === 2) {
                const dateStart = new Date()
                dateStart.setMonth(dateStart.getMonth() - 1)
                this.starttime = dateStart
                this.endtime = new Date()
            } else if (this.timeIndex === 3) {
                const dateStart = new Date()
                dateStart.setFullYear(dateStart.getFullYear() - 1)
                this.starttime = dateStart
                this.endtime = new Date()
            }
            // if (this.checkIndex === 1) {
            //     // 历史--小时
            //     if (this.timeIndex === 0) {
            //         this.urlpage = '/cw/surface_data/surf_hour_data'
            //         this.urlOut = '/cw/export_surf_data/surf_hour_data'
            //         this.urlList = '/cw/surface_data/list/surf_hour_data'
            //         // 历史 --- 日
            //     } else if (this.timeIndex === 1) {
            //         this.urlpage = '/cw/surface2/page/surf_day_data'
            //         this.urlOut = '/cw/surface2/export/surf_day_data'
            //         this.urlList = '/cw/surface2/list/surf_day_data'
            //     }
            // }
            // if (this.checkIndex === 0) {
            //     // 统计 --- 小时
            //     if (this.timeIndex === 0) {
            //         this.urlpage = '/cw/surface_stat/surf_hour_data'
            //         // this.urlOut = '/cw/export_surf_stat2/surf_hour_data'
            //         this.urlOut = '/cw/surface_stat2/export/surf_hour_data'
            //         this.urlList = '/cw/surface_stat/list/surf_hour_data'
            //         // 统计 --- 日
            //     } else if (this.timeIndex === 1) {
            //         this.urlpage = '/cw/surface_stat2/page/surf_day_data'
            //         this.urlOut = '/cw/surface_stat2/export/surf_day_data'
            //         this.urlList = '/cw/surface_stat2/list/surf_day_data'
            //     }
            // }
        },
        startTimeChange(e: any) {
            this.starttime = e
            if (this.timeIndex === 0) {
                // console.log('小时')
                if (this.sameDate(e)) {
                    this.timeStartList.splice(0)
                    const endHour = moment().hour() + 1
                    this.timeStartList = this.initTime(endHour)
                    this.selectedStartHour = this.timeStartList[this.timeStartList.length - 1]
                } else {
                    this.timeStartList = this.initTime(24)
                }
            }
        },
        endTimeChange(e: any) {
            this.endtime = e
            if (this.timeIndex === 0) {
                if (this.sameDate(e)) {
                    this.timeEndList.splice(0)
                    const endHour = moment().hour() + 1
                    this.timeEndList = this.initTime(endHour)
                    this.selectedEndHour = this.timeEndList[this.timeEndList.length - 1]
                } else {
                    this.timeEndList = this.initTime(24)
                }
            }
        },
        queryCondition() {
            let start = ''
            let end = ''
            let startHour = ''
            let endHour = ''
            if (this.timeIndex === 0) {
                startHour = this.selectedStartHour
                endHour = this.selectedEndHour
                if (!startHour || !endHour) {
                    this.$Message.warning('请检查时间是否选择')
                    return;
                }
                start = moment(this.starttime).format('YYYYMMDD') + startHour + '0000'
                end = moment(this.endtime).format('YYYYMMDD') + endHour + '0000'
            } else if (this.timeIndex === 1) {
                start = moment(this.starttime).format('YYYYMMDD000000')
                end = moment(this.endtime).format('YYYYMMDD000000')
            } else if (this.timeIndex === 2) {
                start = moment(this.starttime).format('YYYYMM00000000')
                end = moment(this.endtime).format('YYYYMM00000000')
            } else if (this.timeIndex === 3) {
                start = moment(this.starttime).format('YYYY0000000000')
                end = moment(this.endtime).format('YYYY0000000000')
            }
            if (this.typeId === 'shandian') {
                if (this.timeIndex === 0 || this.timeIndex === 1) {
                    const d1 = new Date(`${moment(start, 'YYYYMMDDHHmmss').format('YYYY-MM-DD,HH:mm:ss')}`);
                    const d2 = new Date(`${moment(end, 'YYYYMMDDHHmmss').format('YYYY-MM-DD,HH:mm:ss')}`);
                    const x = d2.getTime() - d1.getTime();
                    // console.log(d1)
                    // console.log(d2)
                    // console.log(x)
                    if (x / 1000 / 3600 / 24 > 1) {
                        this.$Message.error('时间区间跨度不能大于一天')
                        return
                    }
                } else {
                    this.$Message.error('时间区间跨度不能大于一天')
                    return
                }
            }
            const timeRange = `${start},${end}`
            const {typeId, stationList, stationId, stateId, warnlevelId, warnRangeId} = this         
            // this.warnlevelList.unshift({id: 'all', text: '全部'})
            // this.warnlevelId = this.warnlevelList[0].id
            const condition = {
                typeId,
                stationList,
                stationId,
                timeRange,
                // stateId,
                warnlevelId,
                warnRangeId,
            }as any
            let maxValue = 0
            let minValue = 0
            if (this.warnlevelId !== 'all') {
                this.warnlevelList.map((e: any) => {
                    if (e.id === this.warnlevelId) {
                        condition.maxValue = e.maxValue
                        condition.minValue = e.minValue
                    }
                })
            }
            const urlpage = this.urlpage
            const urlList = this.urlList
            // console.log(condition)
            // console.log(urlpage)
            // console.log(urlList)
            this.$emit('getCondition', {
                condition, urlpage, urlList,
            })
        },
        exportData() {
            let start = ''
            let end = ''
            let startHour = ''
            let endHour = ''
            if (this.timeIndex === 0) {
                startHour = this.selectedStartHour
                endHour = this.selectedEndHour
                if (!startHour || !endHour) {
                    this.$Message.warning('请检查时间是否选择')
                    return;
                }
                start = moment(this.starttime).format('YYYYMMDD') + startHour + '0000'
                end = moment(this.endtime).format('YYYYMMDD') + endHour + '0000'
            } else if (this.timeIndex === 1) {
                start = moment(this.starttime).format('YYYYMMDD')
                end = moment(this.endtime).format('YYYYMMDD')
            } else if (this.timeIndex === 2) {
                start = moment(this.starttime).format('YYYYMM')
                end = moment(this.endtime).format('YYYYMM')
            } else if (this.timeIndex === 3) {
                start = moment(this.starttime).format('YYYY')
                end = moment(this.endtime).format('YYYY')
            }
            const timeRange = `${start},${end}`
            const {typeId, stationId, stateId, warnlevelId} = this
            const condition = {
                typeId,
                stationId,
                timeRange,
                stateId,
                warnlevelId,
            }
            const fileName = `数据查询-${start}-${start}.csv`
            // axios({
            //     url,
            //     method: 'get',
            //     params: condition,
            //     responseType: 'blob',
            // }).then((res) => {
            //     // console.log(res)
            //     // console.log(res.headers)
            //     // console.log(res.headers['content-disposition'])
            //     const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'})
            //     const link = document.createElement('a')
            //     link.style.display = 'none'
            //     link.href = URL.createObjectURL(blob)
            //     link.download = fileName // 下载的文件名
            //     document.body.appendChild(link)
            //     link.click()
            //     document.body.removeChild(link)
            // }).catch((errorMsg) => {
            //     this.$Message.error('数据量过大，建议分批导出')
            // })
        },
        hourTime() {
            const startHour = moment().hour() + 1
            const endHour = moment().hour() + 1
            this.timeStartList = this.initTime(24)
            this.timeEndList = this.initTime(endHour)
            this.selectedStartHour = this.timeStartList[startHour - 2]
            this.selectedEndHour = this.timeEndList[this.timeEndList.length - 1]
        },
        initTime(endHour: any) {
            const timeList: any[] = []
            const arr = []
            for (let i = 0; i < endHour; i++) {
                const num = i < 10 ? '0' + i : i
                timeList.splice(i, 1, num)
            }
            return timeList
        },
        sameDate(date: any) {
            return moment().year() === moment(date).year() && moment().month() === moment(date).month()
             && moment().date() === moment(date).date()
        },
        getWarnRangeData() {
            // return request.get('/admin/warning_threshold/query?element=lightning&status=1', null)
            // .then((data) => {
            //     // console.log(data.data)
            //     if (data && data.data.length > 0) {
            //         data.data.map((e: any) => {
            //             this.warnRangeList.push(`${e.maxValue}km`)
            //         })
            //         this.warnRangeId = this.warnRangeList[this.warnRangeList.length - 1]
            //     }
            // })
        },
        getdaqiWarnRangeData() {
            return request.get('/admin/warning_threshold/query?element=electric_field_intensity&status=1', null)
            .then((data) => {
                if (data && data.data.length > 0) {
                    data.data.map((e: any) => {
                        this.warnlevelList.push({
                            id: e.id,
                            text: e.level.slice(0, 2),
                            maxValue: e.maxValue * 1000,
                            minValue: e.minValue * 1000,
                        })
                    })
                    this.warnlevelList.unshift({id: 'all', text: '全部'})
                    this.warnlevelId = this.warnlevelList[0].id
                }
            })
        },
    },
})
